.HWWProcessItem {
    margin: 1rem 0 0 0;
    display: flex;
}

@media screen and ( min-width: 500px ) {
    .HWWProcessItem {
        padding: 0 1rem;
        max-width: 95%;
        margin: 0;
    }
}

.HWWProcessItem img {
    margin: auto .2rem;
    height: 4rem;
}

.HWWProcessItemTexts {
    padding-left: .8rem;
    text-align: left;
}

.HWWProcessItemTexts h2{
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
}

@media screen and (min-width: 800px) {
    .HWWProcessItemTexts h2{
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1400px) {
    .HWWProcessItemTexts h2{
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1500px) {
    .HWWProcessItemTexts h2{
        font-size: 2rem;
    }
}

@media screen and (min-width: 1700px) {
    .HWWProcessItemTexts h2{
        font-size: 2.3rem;
    }
}

.HWWProcessItemTexts p{
    margin-top: .5rem;
    font-size: .9rem;
    color: #d8d8d8;
}

@media screen and (min-width: 800px) {
    .HWWProcessItemTexts p{
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 1400px) {
    .HWWProcessItemTexts p{
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 1500px) {
    .HWWProcessItemTexts p{
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1700px) {
    .HWWProcessItemTexts p{
        font-size: 1.5rem;
    }
}