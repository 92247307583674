.PeopleAndJobContainer {
    padding: 2rem 0 1rem 0;
    max-width: 780px;
    margin: 0 auto;
}

@media screen and (min-width: 600px) {
    .PeopleContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 800px) {
    .PeopleContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1500px) {
    .PeopleAndJobContainer {
        max-width: 980px;
    }
}