.ContactItemContainer {
    text-align: left;
    margin-bottom: 1rem;
}

.ContactItemContainer img {
    height: 50px;
    margin: 0;
}

.ContactItemContainer h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
    margin-top: .5rem;
}

@media screen and (min-width: 1500px) {
    .ContactItemContainer h2 {
        font-size: 2rem;
    }
}

.ContactItemContainer p {
    font-size: 1rem;
    margin: .5rem 0 1rem 0 ;
}

@media screen and (min-width: 1500px) {
    .ContactItemContainer p {
        font-size: 1.3rem;
    }
}