.SidebarContainer { 
    transition: 0.3s ease-in-out;
    background-color: #110F2E;
    align-items: center;
    text-align: center;
    position: fixed;
    height: 100%;
    z-index: 999;
    width: 100%;
    left: 0;
    top: 0;
}

.SidebarContainer img {
    margin: 1em auto;
}

@media (min-width: 770px) {
    .SidebarContainer {
        display: none;
    }
}

.LogoContainer {
    position: relative;
}

.CloseMenu {
    position: absolute;
    cursor: pointer;
    top: 1.5em;
    right: 1em;
}

.ItemText span{
    font-size: 1.5em;
}

.ButtonContactSidebar {
    text-decoration: none !important;
    background-color: #1A5EF3;
    color: white !important;
    margin: 0 auto !important;
    border-radius: 5px;
    padding: .5rem 0;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: block;
    height: 2.3rem;
    border: none;
    width: 150px;
}

.LanguageSidebar {
    margin: 0 auto;
}