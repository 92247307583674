.CardHomeContainer {
    -webkit-box-shadow: 0px 0px 5px rgba(209,207,209,1);
    -moz-box-shadow: 9px 11px 5px -4px rgba(209,207,209,1);
    box-shadow: 0px 0px 5px rgba(209,207,209,1);
    padding: 2.5rem 1.5rem .5rem 1.5rem;
    background-color: white;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 4rem auto;
    position: relative;
    text-align: center;
    max-width: 320px;
    width: 90%;
}

@media screen and (min-width: 600px) {
    .CardHomeContainer {
        width: 80%;
    }
}

@media screen and (min-width: 1500px) {
    .CardHomeContainer {
        max-width: 380px;
    }
}

.CardHomeContainer img {
    transform: translate(-50%, -50%);
    position: absolute;
    height: 5rem;
    left: 50%;
    top: 0;
}

.CardHomeContainer h2 {
    font-size: 23px;
}

@media screen and (min-width: 1500px) {
    .CardHomeContainer h2{
        font-size: 1.8rem;
    }
}

.CardHomeContainer p {
    color: #776A6A;
    font-size: 20px;
}

@media screen and (min-width: 1500px) {
    .CardHomeContainer p{
        font-size: 1.5rem;
    }
}

