.CardWWDContainer {
    text-align: center;
    margin: 3rem auto 0 auto;
    max-width: 240px;
    width: 90%;
}

@media screen and (min-width: 600px) {
    .CardWWDContainer{
        margin: 2rem auto 0 auto;
    }
}

@media screen and (min-width: 1500px) {
    .CardWWDContainer{
        max-width: 310px;
    }
}

.CardWWDContainer img {
    width: 5rem;
}

@media screen and (min-width: 1500px) {
    .CardWWDContainer h2{
        font-size: 1.8rem;
    }

    .CardWWDContainer p{
        font-size: 1.6rem;
    }

    .CardWWDContainer img {
        width: 7rem;
    }
}