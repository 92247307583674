.SliderWWDContainer {
    max-width: 500px;
    padding: 0 1rem;
    margin: 0 auto 6rem auto;
    z-index: 0;
}

.Slide-WWD img {
    width: 60% !important;
}

@media screen and (min-width: 600px) {
    .SliderWWDContainer {
        max-width: 700px;
        display: block;
    }
}

@media screen and (min-width: 900px) {
    .SliderWWDContainer {
        max-width: 1000px;
    }
}