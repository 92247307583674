.TechnologiesLogosContainer {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    max-width: 80%;
    margin: 0 auto;
    display: grid;
    gap: 10px;
}

@media screen and (min-width: 800px) {
    .TechnologiesLogosContainer {
        grid-template-columns: repeat(6, 1fr);
    }
}

.TechnologiesLogosContainer img {
    max-width: 80%;
    margin: 0 auto;
}

@media screen and (min-width: 800px) {
    .TechnologiesLogosContainer img {
        max-width: 60%;
    }
}