.PeopleItemContainer {
    text-align: center;
    padding-top: 1rem;
    margin: 0 auto;
    width: 95%;
}

@media screen and (min-width: 600px) {
    .PeopleItemContainer {
        margin: 0 auto 2rem auto;
    }
}

.PeopleItemContainer img {
    width: 50%;
}

@media screen and (min-width: 600px) {
    .PeopleItemContainer img {
        width: 70%;
    }
}

.PeopleItemContainer h3 {
    margin: 1rem 1rem .4rem 1rem;
    font-size: 24px;
}

@media screen and (min-width: 600px) {
    .PeopleItemContainer h3 {
        font-size: 20px;
    }
}

@media screen and (min-width: 1500px) {
    .PeopleItemContainer h3 {
        font-size: 28px;
    }
}

.PeopleItemContainer p {
    color: #25D3E7;
    font-size: 18px;
    margin: 0;
}

@media screen and (min-width: 600px) {
    .PeopleItemContainer p {
        font-size: 15px;
    }
}

@media screen and (min-width: 1500px) {
    .PeopleItemContainer p {
        font-size: 18px;
    }
}