body {
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}
