h1 {
    font-weight: 700;
    font-size: 3rem;
}

p {
    font-size: 1.2rem;
}

.HeroAboutUs {
    background-image: url(../../assets/imgs/AboutUs/BannerAboutUs.png);
    background-repeat: no-repeat;
    padding: 64px 1rem 1rem 1rem;
    background-color: #110F2E;
    background-size: cover;
    text-align: center;
    height: auto;
}

@media screen and (min-width: 600px) {
    .HeroAboutUs {
        grid-template-columns: repeat(3, 1fr);
        text-align: left;
        display: grid;
    }
}

@media screen and (min-width: 1300px) {
    .HeroAboutUs {
        height: 100vh;
    }
}

.HeroAboutUsTexts {
    padding: .1rem 0 .5rem 0;
    height: auto;
}

@media screen and (min-width: 600px) {
    
    .HeroAboutUsTexts {
        padding-left: 1rem;
        max-width: 600px;
        grid-column: 1/3;
    }

    .HeroAboutUsTexts p{
        font-size: 1.3rem;
    }
}

@media screen and (min-width: 1200px) {
    .HeroAboutUsTexts {
        padding-left: 3rem;
        padding-top: 3rem;
        margin: auto 0;
    }

    .HeroAboutUsTexts h1{
        margin-bottom: 2rem !important;
    }
}

@media screen and (min-width: 1400px) {
    .HeroAboutUsTexts {
        max-width: 700px;
    }

    .HeroAboutUsTexts h1{
        font-size: 4rem;
        margin-top: 0 !important;
    }

    .HeroAboutUsTexts p{
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1500px) {
    .HeroAboutUsTexts {
        max-width: 900px;
    }

    .HeroAboutUsTexts h1{
        font-size: 5rem;
        margin-top: 0 !important;
    }

    .HeroAboutUsTexts p{
        font-size: 2rem;
    }
}

.HeroAboutUs h1 {
    color: #3AE0D9;
}

.HeroAboutUs p {
    color: white;
}

@media screen and (min-width: 600px) {
    .HeroAboutUs h1 {
        margin: 4rem 0 0 0;
    }
    
    .HeroAboutUs p {
        margin-bottom: 4rem;
    }
}

.HistoryAndValuesContainer {
    text-align: center;
    padding: 1rem;
}

.HistoryAndValuesPresentation{
    max-width: 1110px;
    margin: 0 auto;
}

@media screen and (min-width: 700px) {
    .HistoryAndValuesPresentation{
        grid-template-columns: repeat(2, 1fr);
        padding: 2rem;
        display: grid;
    }

    .HistoryAndValuesPresentation h1{
        text-align: left;
        max-width: 515px;
    }

    .HistoryAndValuesPresentation p{
        font-size: 1.3rem;
        text-align: left;
    }
}

@media screen and (min-width: 1500px) {
    .HistoryAndValuesPresentation{
        max-width: 1310px;
    }

    .HistoryAndValuesPresentation h1{
        max-width: 515px;
        font-size: 4rem;
        margin: auto 0;
    }

    .HistoryAndValuesPresentation p{
        font-size: 1.6rem;
    }
}

@media screen and (min-width: 600px) {
    .CardsAboutUsContainer {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
    }

}

@media screen and (min-width: 800px) {
    .CardsAboutUsContainer {
        grid-template-columns: repeat(3, 1fr);
        max-width: 870px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1500px) {
    .CardsAboutUsContainer {
        max-width: 1010px;
    }
}

.SimplicityCard {
    text-align: center;
    margin: 3rem auto;
    width: 90%;
}

@media screen and (min-width: 1500px) {
    .SimplicityCard {
        max-width: 280px;
    }

    .SimplicityCard h2{
        font-size: 2.5rem;
    }

    .SimplicityCard p{
        font-size: 1.5rem;
    }
}

.OurTeamContainer {
    background-color: #110F2E;
    text-align: center;
    color: white;
    padding: 1rem;
}

@media screen and (min-width: 600px) {
    .OurTeamContainer h1 {
        margin-top: 4rem;
    }
}

@media screen and (min-width: 1500px) {
    .OurTeamContainer h1 {
        font-size: 5rem;
    }
}

.OurTeamContainer h2 {
    font-size: 1.8rem;
}


@media screen and (min-width: 600px) {
    .OurTeamContainer h2 {
        font-size: 2.2rem;
    }
}

@media screen and (min-width: 1500px) {
    .OurTeamContainer h2 {
        font-size: 2.6rem;
    }
}

.OurTeamContainer p {
    max-width: 800px;
    margin: auto;
}

@media screen and (min-width: 1500px) {
    .OurTeamContainer p {
        font-size: 1.6rem;
    }
}

.JoinUsContainer {
    background-color: #110F2E;
}

@media screen and (min-width: 600px) {
    .JoinUsContainer {
        padding: 2rem 0;
    }
}

.JoinUsContactContainer {
    padding: .1rem 1rem 1.2rem 1rem;
    background-color: #151641;
    text-align: center;
    max-width: 885px;
    margin: 0 auto;
    color: white;
    width: 90%;
}

@media screen and (min-width: 600px) {
    .JoinUsContactContainer {
        max-width: 1000px;
        padding: .3rem 1rem 2rem 1rem;
    }
}

@media screen and (min-width: 1500px) {
    .JoinUsContactContainer {
        max-width: 1500px;
    }
}

.JoinUsContactContainer h1 {
    font-size: 2rem;
}

@media screen and (min-width: 600px) {
    .JoinUsContactContainer h1{
        font-size: 2.3rem;
    }
}

@media screen and (min-width: 1500px) {
    .JoinUsContactContainer h1{
        font-size: 2.5rem;
        margin-bottom: 3rem;
    }
}

.JoinUsBr {
    display: block;
}

@media screen and (min-width: 600px) {
    .JoinUsBr {
        display: none;
    }
}

.ButtonContactJoinUs {
    background-color: #1A5EF3;
    transition: background .3s;
    padding: .8rem 3.5rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    display: block;
    color: white;
    margin: 0 auto;
    border: none;
    text-decoration: none;
    max-width: 60vw;
    width: 15rem;
}

@media screen and (min-width: 1500px) {
    .ButtonContactJoinUs {
        font-size: 22px;
        margin-bottom: 1rem;
    }
}

.ButtonContactJoinUs:hover {
    background-color: #164abd;
}