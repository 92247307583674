.HomeContainer {
    box-sizing: border-box;
    margin-top: 64px;
}

.HeroSectionHome {
    background-image: url("../../assets/imgs/Home/HeroImg.png");
    background-repeat: no-repeat;
    background-color: #110F2E;
    background-position: center -100px;
    background-size: cover;
    position: relative;
    margin-top: -64px;
    padding-top: 64px;
    height: 230vw;
}

@media screen and (max-width: 300px)  {
    .HeroSectionHome {
        height: 260vw;
    }
}

@media screen and (min-width: 300px)  {
    .HeroSectionHome {
        height: 700px;
    }
}

@media screen and (min-width: 480px) {
    .HeroSectionHome {
        height: 120vw;
    }
}

@media screen and (min-width: 650px) {
    .HeroSectionHome {
        height: 85vw;
    }
}

@media screen and (min-width: 1000px) {
    .HeroSectionHome {
        height: 60vw;
    }
}

@media screen and (min-width: 1500px) {
    .HeroSectionHome {
        height: 50vw;
        background-position: center top;
    }
}

.ContactHomeTop {
    padding: 2rem 1rem;
    text-align: center;
    margin: 0 auto;
}

@media screen and (min-width: 700px) {
    .ContactHomeTop {
        max-width: 1000px;
    }
}

@media screen and (min-width: 1200px) {
    .ContactHomeTop {
        margin-top: 2rem;
    }
}

.ContactHomeTop h1 {
    font-weight: 700;
    font-size: 2rem;
    color: white;
    margin: 0;
}

@media screen and (min-width: 300px) {
    .ContactHomeTop h1{
        font-size: 1.8rem;
    }
}

@media screen and (min-width: 1200px) {
    .ContactHomeTop h1 {
        margin-bottom: 3rem;
    }
}

.ContactHomeTop p {
    font-weight: bold;
    font-weight: 500;
    color: #3AE0D9;
}

.ContactSpecialText {
    color: white;
}

@media screen and (min-width: 700px) {
    .ContactHomeTop h1 {
        font-size: 3.5rem;
        font-weight: 500;
    }

    .ContactHomeTop p {
        font-size: 1.3rem;
    }
}

@media screen and (min-width: 1500px) {
    .ContactHomeTop h1 {
        font-size: 4.5rem;
        margin-top: 3rem;
    }

    .ContactHomeTop p {
        font-size: 1.6rem;
    }
}

.SliderCompaniesSection {
    text-align: center;
    position: absolute;
    padding-top: 1rem;
    color: white;
    margin: 0 auto;
    width: 100%;
}

@media screen and (min-width: 300px) {
    .SliderCompaniesSection {
        bottom: 3%;
    }
}

@media screen and (min-width: 1300px) {
    .SliderCompaniesSection {
        bottom: 10%;
    }
}

.SliderCompaniesSection h2{
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 0;
}

@media screen and (min-width: 400px) {
    .SliderCompaniesSection h2{
        font-weight: 700;
        font-size: 2rem;
        margin-top: 0;
    }
}

.WhatWeDoContainer {
    text-align: center;
}

.WhatWeDoContainerTexts {
    padding: 0 .8rem;
}

@media screen and (min-width: 1100px) {
    .WhatWeDoContainerTexts {
        padding: .5rem 15rem;
    }
}

.WhatWeDoContainerTexts h1 {
    font-size: 2.5rem;
}

@media screen and (min-width: 600px) {
    .WhatWeDoContainerTexts h1 {
        font-size: 3rem;
    }
}

@media screen and (min-width: 1500px) {
    .WhatWeDoContainerTexts h1 {
        font-size: 4rem;
    }
}

.WhatWeDoContainerTexts p {
    font-size: 1.2rem;
}

@media screen and (min-width: 600px) {
    .WhatWeDoContainerTexts p {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1500px) {
    .WhatWeDoContainerTexts p {
        font-size: 1.8rem;
    }
}

.WhatWeDoContainerCards {
    margin: 1rem auto;
    max-width: 1200px;
}

@media screen and (min-width: 600px) {
    .WhatWeDoContainerCards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 1000px) {
    .WhatWeDoContainerCards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1500px) {
    .WhatWeDoContainerCards {
        max-width: 1400px;
    }
}

.HowWeWorkContainer {
    background-color: #110F2E;
    padding: 1rem;
    text-align: center;
    color: white;
}

@media screen and (min-width: 800px) {
    .HowWeWorkContainer{
        grid-template-columns: repeat(5, 1fr);
        display: grid;
        padding: 0;
    }
    
    .HWWInfo {
        padding: 1rem 0 2rem 0;
        grid-column: 1 / 4;
        text-align: left;
        margin: auto 0;
    }
}

.HWWItemsTitle{
    margin: auto auto;
    max-width: 80%;
    padding-left: 1rem;
}

@media screen and (min-width: 800px) {
    .HWWItemsTitle h1{
        font-size: 4rem;
    }
}

.HWWImage {
    display: none;
}

.HWWImage img {
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 800px) {
    .HWWImage {
        grid-column: 4 / 6;
        display: block;
    }
}

.TechnologiesContainer {
    padding-bottom: 2rem;
    text-align: center;
}

@media screen and (min-width: 800px) {
    .TechnologiesContainer {
        font-size: 2rem;
        padding-bottom: 4rem;
    }
}

.TechnologiesContainer h1{
    font-size: 1.9rem;
}

@media screen and (min-width: 800px) {
    .TechnologiesContainer h1{
        font-size: 3rem;
    }
}


.LetsStart {
    background-color: #110F2E;
    padding: 3rem 2rem 2rem 2rem;
    height: 60vw;
}

@media screen and (min-width: 700px) {
    .LetsStart {
        height: 40vw;
    }
}

@media screen and (min-width: 1100px) {
    .LetsStart {
        height: 25vw;
    }
}

.LetsStartContainer {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FLetsStartBack.png?alt=media&token=688bd971-46bc-444c-8705-73f42a1e10e7);
    background-position: bottom right;
    padding: .1rem 1rem 1.2rem 1rem;
    background-repeat: no-repeat;
    background-color: #151641;
    background-size: cover;
    text-align: left;
}

@media screen and (min-width: 700px) {
    .LetsStartContainer {
        background-position: 20px;
        padding: 2.5rem;
        margin: 0 auto;
        width: 90%;
    }
}

.LetsStartContainer h1 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 0;
    color: white;
}

@media screen and (min-width: 800px) {
    .LetsStartContainer h1 {
        font-size: 2.5rem;
    }
}

.ButtonContact {
    background-color: #1A5EF3;
    transition: background .3s;
    padding: .5rem 1.5rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: block;
    color: white;
    border: none;
    text-decoration: none;
    width: 40vw;
    max-width: 15rem;
}

.ButtonContact:hover {
    background-color: #164abd;
}

@media screen and (min-width: 800px) {
    .ButtonContact {
        padding: 1rem 3.5rem;
        font-weight: 600;
        font-size: 18px;
    }
}

.LetsStartContainerBr {
    display: none;
}

@media screen and (min-width: 700px) {
    .LetsStartContainerBr {
        display: block;
    }
}