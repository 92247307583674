.SliderHomeContainer {
    background: linear-gradient( to right,  transparent 0%, #110F2E 20%, #110F2E 80%, transparent 100%);
    justify-content: center;
    margin-bottom: 2rem;
    display: flex;
    height: 5rem;
    width: 100%;
}

@media screen and (min-width: 600px) {
    .SliderHomeContainer {
        margin-bottom: 0;
    }
}

.LogosCompanies {
    /* padding: 1rem 0; */
    margin: 0 auto;
    display: flex;
    bottom: 30px;
    width: 90%;
}

.LogosCompanies img {
    margin: .5rem 1rem;
}

.LogosCompaniesSlider {
    margin: 0 auto;
    width: 90%;
}

.LogosCompaniesSlider .slick-list  .slick-track {
    margin: auto 0 !important;
}

.LogosCompaniesSlider img {
    width: auto !important;
    margin: auto 0;
}

.CompanyImg1 {
    margin: 1.6rem auto !important;
    height: 2.5rem;
}

.CompanyImg2 {
    margin-top: -2.5rem !important;
    margin-left: 1.5rem !important;
    height: 10rem;
}

.CompanyImg3 {
    margin-top: 0.5rem !important;
    height: 4rem;
}

.CompanyImg4 {
    margin: 0.5rem 0 0 -5rem !important;
    height: 4rem;
}

.CompanyImg5 {
    margin: 1rem 0 0 -4rem !important;
    height: 3rem;
}


