h1 {
    font-weight: 700;
    font-size: 3rem;
}

p {
    font-size: 1.2rem;
}

.HeroWWD {
    background-image: url(../../assets/imgs/WhatWeDo/BannerWWD.png);
    background-repeat: no-repeat;
    padding: 64px 1rem 1rem 1rem;
    background-color: #110F2E;
    background-size: cover;
    text-align: center;
    height: auto;
}

@media screen and (min-width: 600px) {
    .HeroWWD {
        grid-template-columns: repeat(3, 1fr);
        text-align: left;
        display: grid;
    }
    
    .HeroWWDTexts {
        padding-left: 1rem;
        max-width: 600px;
        grid-column: 1/3;
    }
}

.HeroWWDTexts h1 {
    color: #3AE0D9;
}

.HeroWWDTexts p {
    color: white;
}

@media screen and (min-width: 1200px) {
    .HeroWWDTexts {
        padding-left: 3rem;
        padding-top: 3rem;
    }

    .HeroWWDTexts h1{
        margin-top: 10rem !important;
        margin-bottom: 2rem !important;
    }
}

@media screen and (min-width: 1300px) {
    .HeroWWD {
        height: 100vh;
    }
}

@media screen and (min-width: 1400px) {
    .HeroWWDTexts {
        max-width: 700px;
        margin: auto 0;
    }

    .HeroWWDTexts h1{
        font-size: 4rem;
        margin-top: 0 !important;
    }

    .HeroWWDTexts p{
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1500px) {
    .HeroWWDTexts {
        max-width: 900px;
        margin: auto 0;
    }

    .HeroWWDTexts h1{
        font-size: 5rem;
        margin-top: 0 !important;
    }

    .HeroWWDTexts p{
        font-size: 2rem;
    }
}


@media screen and (min-width: 600px) {
    .HeroWWD h1 {
        margin: 4rem 0 0 0;
    }
    
    .HeroWWD p {
        margin-bottom: 4rem;
    }
}

.CardsAboutUsContainer {
    padding: 2rem 0 4rem 0;
}

@media screen and (min-width: 600px) {
    .CardsAboutUsContainer {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
    }

}

@media screen and (min-width: 800px) {
    .CardsAboutUsContainer {
        grid-template-columns: repeat(3, 1fr);
        max-width: 870px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1500px) {
    .CardsAboutUsContainer {
        max-width: 1070px;
    }
}

.OurWorkContainer {
    background-color: #110F2E;
    text-align: center;
    padding: 1rem;
    color: white;
}

@media screen and (min-width: 600px) {
    .OurWorkContainer {
        padding: 1rem 1rem 2.5rem 1rem;
    }
}

@media screen and (min-width: 1500px) {
    .OurWorkContainer h1{
        font-size: 5rem;
    }
}

.OurWorkContainer p {
    max-width: 780px;
    margin: 0 auto 2rem auto;
}

@media screen and (min-width: 600px) {
    .OurWorkContainer p {
        margin: 0 auto 4rem auto;
    }
}

@media screen and (min-width: 1500px) {
    .OurWorkContainer p {
        font-size: 1.7rem;
        max-width: 850px;
    }
}

.CompaniesContainer {
    text-align: center;
    padding: 4rem 1rem;
}

.CompaniesContainer h1{
    font-size: 2rem;
}

@media screen and (min-width: 1500px) {
    .CompaniesContainer h1 {
        font-size: 4rem;
        margin-bottom: 1rem;
    }

    .CompaniesContainer p {
        font-size: 2rem;
    }
}

.CompaniesLogos {
    -webkit-box-shadow: 0px 0px 5px rgba(209,207,209,1);
    -moz-box-shadow: 9px 11px 5px -4px rgba(209,207,209,1);
    box-shadow: 0px 0px 5px rgba(209,207,209,1);
    border-radius: 12px;
    padding: 2rem 1rem;
    margin: 4rem auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 550px;
}

@media screen and (min-width: 500px) {
    .CompaniesLogos {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        padding: 1.5rem 0 0 0;
    }
}

@media screen and (min-width: 1200px) {
    .CompaniesLogos {
        justify-content: space-around;
        display: flex;
        flex-direction: row;
        padding: 0;
        max-width: 90%;
        padding-left: 2rem;
    }
}

.CompaniesLogos img {
    margin: 1rem auto;
}

@media screen and (min-width: 500px) {
    .CompaniesLogos img {
        margin: auto auto;
    }
}

@media screen and (min-width: 800px) {
    .CompaniesLogos img {
        margin: auto auto;
    }
}

.ContractSection {
    background-color: #110F2E;
    text-align: center;
    padding: 1rem 1rem 3rem 1rem;
    color: white;
}

.ContractSection h1 {
    font-size: 2rem;
}

@media screen and (min-width: 1500px) {
    .ContractSection h1 {
        font-size: 3rem;
    }
}

.ContractSectionButton {
    margin: 2rem auto 0 auto !important;
    text-decoration: none !important;
    background-color: #1A5EF3;
    transition: background .3s;
    padding: .8rem 1rem;
    border-radius: 14px;
    max-width: 430px;
    color: white;
    display: flex;
    border: none;
    bottom: 0;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 1500px) {
    .ContractSectionButton {
        max-width: 530px;
    }
}

.ContractSectionButton:hover {
    background-color: #164abd;
}

.ContractSectionButton img {
    height: 30px;
    width: 30px;
}

@media screen and (min-width: 500px) {
    .ContractSectionButton img {
        margin-left: 2rem;
        height: 40px;
        width: 40px;
    }
}

@media screen and (min-width: 1500px) {
    .ContractSectionButton img {
        height: 50px;
        width: 50px;
    }
}

.ContractSectionButton h3 {
    color: white;
    text-decoration: none;
    margin: auto 0 auto 1rem;
    font-size: 1.5rem;
}

@media screen and (min-width: 500px) {
    .ContractSectionButton h3 {
        font-size: 1.8rem;
    }
}

@media screen and (min-width: 1500px) {
    .ContractSectionButton h3 {
        font-size: 2.5rem;
    }
}