.SliderHWWContainer {
    text-align: center;
    box-sizing: border-box;
    padding: 0;
}

@media screen and (min-width: 1400px) {
    .SliderHWWContainer {
        margin-bottom: 12rem;
    }
}

@media screen and (min-width: 1800px) {
    .SliderHWWContainer {
        margin-bottom: 20rem;
    }
}