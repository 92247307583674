.NavbarContainer {
    background-color: #110F2E !important;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: -1px;
    font-weight: 600;
    font-size: 16px;
    padding: .5rem;
    width: 100%;
    z-index: 990 !important;
}

.ToolbarNavbar {
    justify-content: space-between !important;
    padding-right: 0 !important;
}

@media screen and (min-width: 1500px) {
    .NavbarContainer {
        padding: 1rem;
        font-size: 20px;
    }
}

.MobileButton {
    display: block;
    margin: 0 !important;
}

@media screen and (min-width: 770px) {
    .MobileButton {
        display: none !important;
    }
}

.Links {
    display: none;
}

@media screen and (min-width: 770px) {
    .Links, .LinksMiddle, .LinksRight {
        display: flex;
    }

    .Links {
        width: 100%;
    }

    .LinksMiddle {
        margin: 0 0 0 auto;
    }
    
    .LinkNavbar {
        text-decoration: none !important;
        margin: 0.5rem !important;
        color: white !important;
        padding-bottom: 5px;
        border-collapse: collapse;
    }

    .LinkNavbar:after{ 
        content: '';
        display: block;
        width: 0;
        height: 4px;
        background: #3AE0D9;
        border-radius: 1px;
        margin-top: 5px;
        margin-bottom: -5px;
        transition: width .3s;
    }

    .LinkNavbar:hover::after {
        width: 100%;
    }

    .LinkNavbarActive {
        text-decoration: none !important;
        margin: .5rem !important;
        color: white !important;
        padding-bottom: 5px;
        border-collapse: collapse;
    }

    .LinkNavbarActive:after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: #3AE0D9;
        margin-top: 5px;
        margin-bottom: -5px;
        border-radius: 1px;
    }

    .LinksRight {
        margin: 0 0 0 auto;
    }
}

@media screen and (min-width: 1050px) {
    .LinkNavbar, .LinkNavbarActive {
        text-decoration: none !important;
        margin: 1rem 2.5rem .5rem 2.5rem!important;
        color: white !important;
    }
}

.ButtonContact {
    display: none;
}

@media screen and (min-width: 770px) {
    .ButtonContact {
        margin: auto 1rem auto 0 !important;
        text-decoration: none !important;
        background-color: #1A5EF3;
        transition: background .3s;
        color: white !important;
        padding: .5rem 2.5rem;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        display: block;
        height: 2.3rem;
        border: none;
    }

    .ButtonContact:hover {
        background-color: #164abd;
    }
}
