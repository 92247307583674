.ContactContainer {
    padding: 64px 1rem 1rem 1rem;
    background-color: #110F2E;
    text-align: center;
    color: white;
}

@media screen and (min-width: 800px) {
    .ContactContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-top: 85px;
    }
}

@media screen and (min-width: 800px) {
    .ContactContainer {
        padding-top: 95px;
    }
}

@media screen and (min-width: 1500px) {
    .ContactContainer {
        padding-top: 131px;
        padding-bottom: 8rem;
    }
}

.ContactContainer h1 {
    font-size: 2.5rem;
    margin: 0;
}

.ContactContainerTexts{
    padding: 1rem 1.5rem .5rem 1.5rem;
}

@media screen and (min-width: 800px) {
    .ContactContainerTexts{
        text-align: left;
        padding: 1rem 2rem 0 2rem;
    }
}

.ItemsContainer {
    display: none;
}

@media screen and (min-width: 800px) {
    .ItemsContainer {
        display: block;
    }
}

@media screen and (min-width: 1200px) {
    .ItemsContainer {
        padding-top: 1rem;
    }
}

@media screen and (min-width: 1500px) {
    .ItemsContainer {
        padding-top: 2rem;
    }
}

.FormSection {
    position: relative;
}

.FormSection form {
    padding: 0rem .5rem;
    border-radius: 15px;
    max-width: 630px;
    margin: 0 auto;
    background-color: white;
    padding: 1rem 1.5rem 1rem 1.5rem;
}

@media screen and (min-width: 1300px) {
    .FormSection form{
        padding: 0rem 1.5rem 1rem 1.5rem;
    }
}

@media screen and (min-width: 600px) {
    .EmailPhoneForm {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}


.MessageForm p {
    margin: 0rem 0 1.3rem 0;
    font-size: .9rem;
    text-align: right;
    color: black;
}

.SendButtonForm {
    background-color: #1A5EF3;
    margin: 0 auto 2rem auto;
    padding: 1rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    border: none;
    width: 100%;
    font-size: 1.5rem;
}

@media screen and (min-width: 1500px) {
    .SendButtonForm {
        padding: 1.5rem 2rem;
        margin: 0 auto 3rem auto;
    }
}

.SendButtonForm:hover {
    background-color: #164abd;
}
