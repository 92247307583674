h1 {
    font-weight: 700;
    font-size: 3rem;
}

p {
    font-size: 1.2rem;
}

.HeroHWW {
    background-image: url(../../assets/imgs/HowWeWork/BannerHWW.png);
    background-repeat: no-repeat;
    /* padding: 64px 1rem 1rem 1rem; */
    background-color: #110F2E;
    background-size: cover;
    text-align: center;
    color: white;
    height: auto;
}

@media screen and (min-width: 1300px) {
    .HeroHWW {
        height: 100vh;
    }
}

.HeroHWW h1 {
    color: #3AE0D9;
    text-align: left;
    font-size: 2.5rem;
}

@media screen and (min-width: 600px) {
    .HeroHWW h1 {
        margin: 4rem 0 0 0;
        font-size: 3rem;
    }
}

@media screen and (min-width: 1400px) {
    .HeroHWW h1 {
        font-size: 3rem;
        margin-top: 0rem;
    }
}

@media screen and (min-width: 1600px) {
    .HeroHWW h1 {
        font-size: 5rem;
    }
}

.HeroHWWContents {
    padding: 1rem 0 2rem 0;
}

@media screen and (min-width: 1400px) {
    .HeroHWWContents {
        margin-top: 6%;
    }
}

.HeroHWWContentsTitle {
    padding-left: 1rem;
    margin-bottom: 1.5rem;
} 

@media screen and (min-width: 700px) {
    .HeroHWWProcess {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.MethodologiesContainer {
    text-align: center;
    padding: 1rem 1rem 3rem 1rem;
}

@media screen and (min-width: 600px) {
    .MethodologiesContainer {
        text-align: center;
        padding: 1rem 1rem 5rem 1rem;
    }
}

.MethodologiesContainer h1{
    font-size: 1.5rem;
}

@media screen and (min-width: 600px) {
    .MethodologiesContainer h1{
        font-size: 3rem;
    }

    .MethodologiesContainer img{
        max-width: 570px;
        margin: auto;
    }
}

@media screen and (min-width: 800px) {
    .MethodologiesContainer img{
        max-width: 90%;
        margin: 1rem auto auto auto;
    }
}

.WeWork {
    background-color: #110F2E;
    color: white;
    padding: 1rem;
}

@media screen and (min-width: 800px) {
    .WeWork {
        background-color: #110F2E;
        color: white;
        padding: 3rem 1rem 3rem 4rem;
    }

    .WeWorkContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 1060px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1500px) {
    .WeWorkContainer {
        max-width: 1460px;
    }
}

.WeWorkTexts {
    text-align: center;
}

@media screen and (min-width: 800px) {
    .WeWorkTexts {
        text-align: left;
    }
}

.WeWorkTexts p {
    max-width: 515px;
    margin: 1.5rem auto;
}

@media screen and (min-width: 1500px) {
    .WeWorkTexts h2 {
        font-size: 3.5rem !important;
    }

    .WeWorkTexts p {
        max-width: 650px;
        margin-left: 0;
        font-size: 1.6rem;
    }
}

.WeWorkTexts h2 {
    font-size: 1.7rem;
}

@media screen and (min-width: 900px) {
    .WeWorkTexts h2 {
        font-size: 2.5rem;
    }
}

.WeWorkIcons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem 0;
    gap: 1rem;
}

@media screen and (min-width: 400px) {
    .WeWorkIcons {
        max-width: 400px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 600px) {
    .WeWorkIcons {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
}

@media screen and (min-width: 800px) {
    .WeWorkIcons {
        gap: 2rem 0;
        margin: auto 0;
        padding-left: 1.5rem;
        max-width: 600px;
    }
}

@media screen and (min-width: 900px) {
    .WeWorkIcons {
        padding-left: 4rem;
    }
}

@media screen and (min-width: 1500px) {
    .WeWorkIcons {
        max-width: 800px;
        padding-top: 1rem;
        padding-bottom: 0;
    }
}

.WeWorkIcons img{
    margin: 0 auto;
    height: 90px;
}

@media screen and (min-width: 400px) {
    .WeWorkIcons img{
        height: 100px;
    }
}

@media screen and (min-width: 1500px) {
    .WeWorkIcons img{
        height: 150px;
    }
}

.WeWorkScreensContainer{
    width: 100%;
    margin: 4rem auto;
}

@media screen and (min-width: 800px) {
    .WeWorkScreensContainer{
        margin: 8rem auto 6rem -1rem;
    }
}

@media screen and (min-width: 1500px) {
    .WeWorkScreensContainer{
        margin: 8rem auto 0rem auto;
    }
}

.TechnologiesContainer {
    padding-bottom: 2rem;
    text-align: center;
}

@media screen and (min-width: 800px) {
    .TechnologiesContainer {
        font-size: 2rem;
        padding-bottom: 8rem;
    }
}

.TechnologiesContainer h1{
    font-size: 1.9rem;
}

@media screen and (min-width: 800px) {
    .TechnologiesContainer h1{
        font-size: 3rem;
        margin-bottom: 5rem;
    }
}

