.App {
  text-align: center;
  font-size: 62.5%;
}

.Slide-WWD img {
  border-radius: 5px;
  width: 100% !important;
}

@media screen and (min-width: 600px) {
  .Slide-WWD img {
    border-radius: 5px;
    width: 65% !important;
  }
}

.Slide-WWD .slick-next::before {
  content: '';
  background: url(./assets/imgs/WhatWeDo/SliderWWD/NextSlider.png);
  background-size: cover;
  position: absolute;
  height: 40px;
  width: 40px;
  z-index: 1000;
  right: 10px;
  top: 0;
}

@media screen and (min-width: 600px) {
  .Slide-WWD .slick-next::before {
    height: 80px;
    width: 80px;
    right: 30px;
    top: -45px;
  }
}

.Slide-WWD  .slick-prev {
  z-index: 1000;
}

.Slide-WWD .slick-prev::before {
  content: '';
  background: url(./assets/imgs/WhatWeDo/SliderWWD/PrevSlider.png);
  background-size: cover;
  position: absolute;
  height: 40px;
  width: 40px;
  left: 10px;
  top: 0px;
}

@media screen and (min-width: 600px) {
  .Slide-WWD .slick-prev::before {
    height: 80px;
    width: 80px;
    left: 30px;
    top: -45px;
  }
}

.MuiFormControl-root {
  width: 100% !important;
} 

.MuiFilledInput-root {
  color: black !important;
  font-size: 1.5rem !important;
}

.css-cio0x1-MuiInputBase-root-MuiFilledInput-root {
  background-color: white !important;
}

.css-cio0x1-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: 1px solid black !important;
}

.css-cio0x1-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 1px solid black !important;
}

.css-1kuvi62-MuiInputBase-root-MuiFilledInput-root {
  background-color: white !important;
}

.css-1kuvi62-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: 1px solid black !important;
}

.css-1kuvi62-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 1px solid black !important;
}

.react-3d-carousel .slider-container .slider-right div,
.react-3d-carousel .slider-container .slider-left div {
  visibility: hidden;
}

.react-3d-carousel {
  height: 100px !important;
}

@media screen and (min-width: 400px) {
  .react-3d-carousel {
    height: 150px !important;
  }
}

@media screen and (min-width: 550px) {
  .react-3d-carousel {
    height: 200px !important;
  }
}

@media screen and (min-width: 650px) {
  .react-3d-carousel {
    height: 300px !important;
  }
}

@media screen and (min-width: 800px) {
  .react-3d-carousel {
    height: 370px !important;
  }
}

.react-3d-carousel .slider-container {
  left: 0;
  margin: 0 auto;
}