.CardAboutUsContainer {
    text-align: center;
    margin: 3rem auto;
    max-width: 240px;
    width: 90%;
}

@media screen and (min-width: 1500px) {
    .CardAboutUsContainer {
        max-width: 310px;
    }

    .CardAboutUsContainer h2{
        font-size: 2.5rem;
    }

    .CardAboutUsContainer p{
        font-size: 1.5rem;
    }
}