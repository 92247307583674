.FooterContainer {
    background-color: #110F2E;
    text-align: center;
    padding: 1rem;
    padding-bottom: 3rem;
}

@media screen and (min-width: 700px) {
    .FooterContainer {
        grid-template-rows: repeat(2, 1fr);
        text-align: left;
        display: grid;
    }
}

@media screen and (min-width: 1100px) {
    .FooterContainer {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: unset;
        padding-bottom: 5rem;
        text-align: left;
        display: grid;
    }
}

@media screen and (min-width: 1500px) {
    .FooterContainer {
        padding-top: 3rem;
    }
}

.FooterLinkContainer {
    color: white;
}

@media screen and (min-width: 700px) {
    .FooterLinksContainer {
        grid-template-columns: repeat(3, 1fr);
        display: grid;
    }
}

@media screen and (min-width: 1100px) {
    .FooterLinksContainer {
        grid-column: 1/4;
        padding-left: 5rem;
    }
}

.FooterLinkContainer h2 {
    font-weight: 500;
    text-align: left;
    margin-left: 10px;
}

@media screen and (min-width: 700px) {
    .FooterLinkContainer h2 {
        margin-left: 0;
    }
}

@media screen and (min-width: 1100px) {
    .FooterLinkContainer h2 {
        margin-top: .5rem;
    }
}

@media screen and (min-width: 1500px) {
    .FooterLinkContainer h2 {
        font-size: 1.8rem;
    }
}

.FooterLinkContainer ul {
    padding-left: 10px;
}

.FooterLinkContainer ul li {
    list-style: none;
    text-align: left;
    font-size: 14px;
    margin: .5rem 0;
}

@media screen and (min-width: 700px) {
    .FooterLinkContainer {
        padding-left: 3rem;
    }

    .FooterLinkContainer ul {
        padding-left: 0;
    }
}

@media screen and (min-width: 1500px) {
    .FooterLinkContainer ul li{
        font-size: 1.6rem;
    }
}

.FooterLink {
    text-decoration: none !important;
    color: white !important;
    transition: opacity .3s;
}

.FooterLink:hover {
    opacity: 0.7;
}

@media screen and (min-width: 700px) {
    .FooterContactContainer {
        padding-left: 2.4rem;
    }
}

@media screen and (min-width: 1100px) {
    .FooterContactContainer {
        grid-column: 4/6;
        margin: 0 auto;
    }
}

.FooterContactItem {
    display: flex;
    color: white;
}

.FooterContactItem a {
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    color: white;
    margin: auto 0;
}

.FooterContactItem a:hover {
    opacity: 0.7;
}

@media screen and (min-width: 1500px) {
    .FooterContactItem a {
        font-size: 20px;
        font-weight: 400;
    }
}

.FooterContactItem img {
    height: 2rem;
    margin: auto .5rem auto 0;
}

@media screen and (min-width: 1500px) {
    .FooterContactItem img {
        height: 3rem;
    }
}

.FooterContactSocial img {
    margin: 1rem 1rem 0 1rem;
}

@media screen and (min-width: 700px) {
    .FooterContactSocial img {
        margin: 1rem 1rem 0 0;
    }
}